import Proj1 from "../assets/proj1.webp";

import Proj2 from "../assets/proj2.webp";

import Proj6 from "../assets/proj6.jpeg";

import Proj7 from "../assets/bumpak.gif";

export const ProjectList = [
    {
        id: "Bumpak",
        name: "Bumpak",
        image: Proj7,
        date: "In comming",
        context: "Freelance",
        category: "Freelance",
        link: "https://bumpak.fr",
        info: "e-commerce website project for handmade products in France, featuring a customization system that I have created to allow customers to personalize their orders online.",
        info2: "REACT / SASS / NODE.JS / MONGODB"
    },
    {
        id: "Nemoya",
        name: "Nemoya",
        image: Proj6,
        date: "2023",
        context: "Freelance",
        category: "Freelance",
        link: "https://nemoya.fr/",
        info: "Last project for a digital agency name Nemoya. Presentation of this company.",
        info2: "HTML / SASS / REACT / GSAP"
    },
    {
        id: "Lautrepain",
        name: "L'autre pain",
        image: Proj2,
        date: "2022",
        category: "Showcase",
        context: "Freelance",
        link: "https://baptistem44.github.io/l-autre-pain/",
        info: "Showcase site to promote an organic baker.",
        info2: "HTML / CSS / SASS / GREENSOCK"
    },
    {
        id: "LeBlaireau",
        name: "Le Blaireau",
        image: Proj1,
        date: "2022",
        category: "Showcase",
        context: "Freelance",
        link: "https://leblaireau-barbier.fr/",
        info: "Showcase site to promote a barber / hairdresser. Display of rates and services, as well as the online booking link.",
        info2: "HTML / CSS / SASS"
    }
]